import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { getAllPosts, getPostById, postComment, postLikeDislike } from '../../api/posts';
import { addUser } from '../../api/user';
import Loader from '../../components/Loader';
import { useLanguage } from '../../store/languageContext';
import { formatDate } from '../../utils';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function Blog() {
  const { blogId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [commentAdding, setCommentAdding] = useState<boolean>(false);
  const [post, setPost] = useState<any>({});
  const [latestPosts, setLatestPosts] = useState<any>([]);
  const [comment, setComment] = useState<any>({});
  const [error, setError] = useState('');
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { language, translations } = useLanguage();

  const blogPage = translations?.blogPage || {};

  useEffect(() => {
    if (blogId) {
      setLoading(true);
      getPostById(blogId)
        .then((res) => {
          if (res.success) {
            setPost(res.body);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [blogId]);

  useEffect(() => {
    getAllPosts('0', '3', 'desc', 'createdAt', language)
      .then((res) => {
        if (res.success) {
          setLatestPosts(res?.body?.rows || []);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const addComment = () => {
    setCommentAdding(true);
    const name = comment.name.split(' ');
    let firstName = '';
    let lastName = '';
    if (name.length > 1) {
      firstName = name[0];
      lastName = name[1];
    } else {
      lastName = name[0];
    }
    addUser({
      firstName,
      lastName,
      email: comment.email
    })
      .then((userRes) => {
        if (userRes.success) {
          postComment({
            content: comment.content,
            postId: blogId,
            userId: userRes.body?.[0].id
          })
            .then((res) => {
              if (res.success) {
                const newComment = {
                  ...res.body,
                  user: userRes.body?.[0]
                };
                setPost({
                  ...post,
                  comments: [...post.comments, newComment]
                });
                setComment({});
              }

              console.log(res);
            })
            .catch((err) => console.log(err))
            .finally(() => setCommentAdding(false));
        }
      })
      .catch((err) => {
        console.log(err);
        setCommentAdding(false);
      });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setComment({ ...comment, [name]: value });
    if (name === 'email') {
      if (emailRegex.test(value)) {
        setError('');
      } else {
        setError(blogPage.emailErrorMsg);
      }
    }
  };

  const encodedUrl = encodeURIComponent(
    `${window.location.protocol}//${window.location.host}/${window.location.pathname}`
  );
  const encodedTitle = encodeURIComponent(post.title);

  const onClickLikeDisLike = (type: 'like' | 'dislike') => {
    postLikeDislike({
      postId: post.id,
      type
    })
      .then((res) => {
        if (res.success) {
          setPost({
            ...post,
            likes: [...post.likes, res.body]
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section className="section blog-wrap bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              {loading ? (
                <div className="col-lg-12 mb-5">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="col-lg-12 mb-5">
                    <div className="single-blog-item">
                      {isImageLoaded && (
                        <img
                          src={post.imageUrl}
                          alt=""
                          width={'100%'}
                          height={'500px'}
                          className="rounded"
                        />
                      )}
                      <img
                        src={post.imageUrl}
                        style={{ display: 'none' }}
                        onLoad={() => setIsImageLoaded(true)}
                        onError={() => setIsImageLoaded(false)}
                      />

                      <div className="blog-item-content bg-white p-5">
                        <div className="blog-item-meta bg-gray py-1 px-2">
                          {post.categories?.length > 0 && (
                            <span className="text-muted text-capitalize mr-3">
                              <i className="ti-pencil-alt mr-2"></i>
                              {post.categories[0].name}
                            </span>
                          )}
                          <span className="text-muted text-capitalize mr-3">
                            <i className="ti-comment mr-2"></i>
                            {post.comments?.length || 0} {blogPage.comments}
                          </span>
                          <span className="text-black text-capitalize mr-3">
                            <i className="ti-time mr-1"></i> {formatDate(post.createdAt)}
                          </span>
                        </div>

                        <p
                          className="lead mb-4 mt-3 mb-4"
                          dangerouslySetInnerHTML={{ __html: post.content }}
                        />

                        <div className="mt-5 clearfix">
                          <ul className="list-inline">
                            <li
                              className="list-inline-item mr-3"
                              onClick={() => onClickLikeDisLike('like')}>
                              {post.likes?.filter((l: any) => l.type == 'like')?.length || 0}
                              <i
                                style={{ cursor: 'pointer' }}
                                className="fas fa-thumbs-up ml-2"
                                aria-hidden="true"
                              />
                            </li>
                            <li className="list-inline-item mr-3">|</li>
                            <li
                              className="list-inline-item"
                              onClick={() => onClickLikeDisLike('dislike')}>
                              {post.likes?.filter((l: any) => l.type == 'dislike')?.length || 0}
                              <i
                                style={{ cursor: 'pointer' }}
                                className="fas fa-thumbs-down ml-2"
                                aria-hidden="true"
                              />
                            </li>
                          </ul>
                          <ul className="float-right list-inline">
                            <li className="list-inline-item mr-3"> {blogPage.share}: </li>
                            <li className="list-inline-item mr-3">
                              <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fab fa-facebook-f" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="list-inline-item mr-3">
                              <a
                                href={`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fab fa-twitter" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item mr-3">
                              <a
                                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-5">
                    <div className="comment-area card border-0 p-5">
                      <h4 className="mb-4">
                        {post.comments?.length} {blogPage.comments}
                      </h4>
                      {post.comments?.length > 0 && (
                        <ul className="comment-tree list-unstyled">
                          {post.comments?.map((c: any) => (
                            <li className="mb-5" key={c.id}>
                              <div className="comment-area-box">
                                <img
                                  alt=""
                                  src="../images/avatar.png"
                                  className="img-fluid float-left mr-3"
                                  style={{
                                    width: '55px'
                                  }}
                                />

                                <h6>
                                  {c.user?.firstName || ''} {c.user?.lastName || ''}
                                </h6>

                                <div className="comment-meta mt-4 mt-lg-0 mt-md-0 float-lg-right float-md-right">
                                  <span className="date-comm">
                                    {blogPage.posted} {format(c.createdAt, 'MMMM d, yyyy')}{' '}
                                  </span>
                                </div>

                                <div className="comment-content">
                                  <p>{c.content}</p>
                                </div>
                                <hr />
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <h4 className="mb-4">{blogPage.commentHeading}</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            id="name"
                            value={comment.name || ''}
                            placeholder={blogPage.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            id="email"
                            value={comment.email || ''}
                            placeholder={blogPage.email}
                            onChange={handleChange}
                          />
                          {error && <label style={{ color: 'red' }}>{error}</label>}
                        </div>
                      </div>
                    </div>

                    <textarea
                      className="form-control mb-3"
                      name="content"
                      id="content"
                      cols={30}
                      rows={5}
                      value={comment.content || ''}
                      onChange={handleChange}
                      placeholder={blogPage.message}
                    />

                    <input
                      className="btn btn-main btn-round-full"
                      type="submit"
                      name="submit-contact"
                      id="submit_contact"
                      disabled={commentAdding || JSON.stringify(comment) == '{}' || error != ''}
                      value={commentAdding ? blogPage.saving : blogPage.btn}
                      onClick={addComment}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <div className="sidebar-wrap">
              <div className="sidebar-widget card border-0 mb-3">
                <img src="../images/avatar.png" alt="" className="img-fluid" />
                <div className="card-body p-4 text-center">
                  <h5 className="mb-0 mt-4">Author Name</h5>
                  <p>Author Profession</p>
                  <p>Author bio</p>

                  <ul className="list-inline author-socials">
                    <li className="list-inline-item mr-3">
                      <a href="#">
                        <i className="fab fa-facebook-f text-muted"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mr-3">
                      <a href="#">
                        <i className="fab fa-twitter text-muted"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mr-3">
                      <a href="#">
                        <i className="fab fa-linkedin-in text-muted"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mr-3">
                      <a href="#">
                        <i className="fab fa-pinterest text-muted"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="sidebar-widget latest-post card border-0 p-4 mb-3">
                <h5>{blogPage.latestPost}</h5>
                {latestPosts?.map((p: any) => (
                  <div className="media border-bottom py-3" key={p.id}>
                    <div className="media-body">
                      <h6 className="my-2">
                        <Link to={`/blog/${p.id}`}>{p.title?.replaceAll('"', '')}</Link>
                      </h6>
                      <span className="text-sm text-muted">
                        {format(p.createdAt, 'dd MMM yyyy')}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="sidebar-widget bg-white rounded tags p-4 mb-3">
                <h5 className="mb-4">{blogPage.tags}</h5>
                {post.tags?.map((t: any) => (
                  <Link to={`/blogs/tag/${t.name}`} key={t.id}>
                    {t.name}
                  </Link>
                ))}
              </div>

              <div className="sidebar-widget bg-white rounded tags p-4 mb-3">
                <h5 className="mb-4">{blogPage.categories}</h5>
                {post.categories?.map((c: any) => (
                  <Link to={`/blogs/category/${c.name}`} key={c.id}>
                    {c.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
