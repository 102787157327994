import React from 'react';

const BlogCardSkeleton = () => {
  return (
    <div className="col-lg-6 col-md-6 mb-5">
      <div className="blog-item">
        <div className="blog-item-content-listing bg-white p-5">
          <div
            className="blog-item-meta placeholder-glow py-1 px-2"
            style={{ height: '60px' }}></div>

          <h3 className="mt-3 mb-3 placeholder-glow">
            <div style={{ height: '80px' }} />
          </h3>
          <p className="mb-4 placeholder-glow" style={{ height: '100px' }} />

          <div
            className="btn btn-small btn-main btn-round-full placeholder-glow"
            style={{
              bottom: '40px',
              position: 'absolute',
              width: '120px',
              height: '45px'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogCardSkeleton;
