import React from 'react';

import { useLanguage } from '../store/languageContext';

export default function Footer() {
  const { translations } = useLanguage();
  const footer = translations?.footer || {};
  return (
    <footer className="footer section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="widget">
              <h4 className="text-capitalize mb-4">{footer.company}</h4>

              <ul className="list-unstyled footer-menu lh-35">
                <li>
                  <a href="#">{footer.termsAndConditions}</a>
                </li>
                <li>
                  <a href="#">{footer.privacy}</a>
                </li>
                <li>
                  <a href="#">{footer.support}</a>
                </li>
                <li>
                  <a href="#">{footer.faq}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <div className="widget">
              <h4 className="text-capitalize mb-4">{footer.quickLints}</h4>

              <ul className="list-unstyled footer-menu lh-35">
                <li>
                  <a href="#">{footer.about}</a>
                </li>
                <li>
                  <a href="#">{footer.services}</a>
                </li>
                <li>
                  <a href="#">{footer.team}</a>
                </li>
                <li>
                  <a href="#">{footer.contact}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="widget">
              <h4 className="text-capitalize mb-4">{footer.subscribe}</h4>
              <p>{footer.subsDesc}</p>

              <form action="#" className="sub-form">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder={`${footer.subsInputPlaceholder}...`}
                />
                <a href="#" className="btn btn-main btn-small">
                  {footer.subscribeBtn}
                </a>
              </form>
            </div>
          </div>

          <div className="col-lg-3 ml-auto col-sm-6">
            <div className="widget">
              <div className="logo mb-4">
                <h3>
                  Gobig<span>Blogs</span>
                </h3>
              </div>
              <h6>
                <a href="tel:+23-345-67890">Support@gobig.com</a>
              </h6>
              <a href="mailto:support@gobig.com">
                <span className="text-color h4">+23-456-8765</span>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-btm pt-4">
          <div className="row">
            <div className="col-sm-12 text-center">
              <ul className="list-inline footer-socials">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com">
                    <i className="ti-facebook mr-2"></i>
                    {footer.facebook}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://twitter.com">
                    <i className="ti-twitter mr-2"></i>
                    {footer.twitter}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.pinterest.com">
                    <i className="ti-linkedin mr-2 "></i>
                    {footer.linkedin}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
