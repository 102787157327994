import { format } from 'date-fns';

export function formatDate(date: any) {
  if (date) {
    // Format the date to get the day and month separately
    const day = format(date, 'd');
    const month = format(date, 'MMMM');

    // Determine the ordinal suffix for the day
    const dayWithOrdinal = day + getOrdinalSuffix(Number(day));

    return `${dayWithOrdinal} ${month}`;
  }
  return date;
}

// Helper function to get the ordinal suffix
function getOrdinalSuffix(day: number) {
  const lastDigit = day % 10;
  const lastTwoDigits = day % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return 'st';
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return 'nd';
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return 'rd';
  } else {
    return 'th';
  }
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}
