import { Link, useNavigate } from 'react-router-dom';

import { useLanguage } from '../store/languageContext';
import { languages } from '../utils/constants';

export default function Header() {
  const { language, setLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const onChangeLanguage = (lang: string) => {
    setLanguage(lang);
    navigate('/blogs');
  };

  const nav = translations?.nav ?? {};

  return (
    <header className="navigation">
      {/* <div className="header-top ">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-2 col-md-4">
              <div className="header-top-socials text-center text-lg-left text-md-left">
                <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                  <i className="ti-facebook"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <i className="ti-twitter"></i>
                </a>
                <a href="https://github.com" target="_blank" rel="noreferrer">
                  <i className="ti-github"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-10 col-md-8 text-center text-lg-right text-md-right">
              <div className="header-top-info">
                <a href="tel:+23-345-67890">
                  Call Us : <span>+23-345-87646</span>
                </a>
                <a href="mailto:support@gmail.com">
                  <i className="fa fa-envelope mr-2"></i>
                  <span>support@gmail.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <nav className="navbar navbar-expand-lg  py-4" id="navbar">
        <div className="container">
          <Link to="/" className="navbar-brand">
            GoBig<span>Blogs</span>
          </Link>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExample09"
            aria-controls="navbarsExample09"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="fa fa-bars"></span>
          </button>

          <div className="collapse navbar-collapse text-center" id="navbarsExample09">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  {nav.blogs}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  {nav.about}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact-us" className="nav-link">
                  {nav.contact}
                </Link>
              </li>
              <li className="nav-item mt-1" style={{ color: 'red' }}>
                |
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="languages"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  {language}
                </a>
                <ul className="dropdown-menu" aria-labelledby="languages">
                  {languages.map((l) => (
                    <li key={l.code} onClick={() => onChangeLanguage(l.code)}>
                      <a className="dropdown-item">{l.name}</a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
