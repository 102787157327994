import { useLanguage } from '../store/languageContext';

const Pagination = ({ currentPage, totalPages, onPageChange }: any) => {
  const { translations } = useLanguage();
  const getPaginationItems = () => {
    const items = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 3) {
      endPage = Math.min(totalPages, 5);
    } else if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(i)}>
          <a className="page-link" href="#">
            {i}
          </a>
        </li>
      );
    }

    if (startPage > 1) {
      items.unshift(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
      items.unshift(
        <li key={1} className="page-item" onClick={() => onPageChange(1)}>
          <a className="page-link" href="#">
            1
          </a>
        </li>
      );
    }
    if (endPage < totalPages) {
      items.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
      items.push(
        <li key={totalPages} className="page-item" onClick={() => onPageChange(totalPages)}>
          <a className="page-link" href="#">
            {totalPages}
          </a>
        </li>
      );
    }

    return items;
  };

  const blogsPage = translations?.blogsPage || {};

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => onPageChange(currentPage - 1)}>
            {blogsPage.prev}
          </a>
        </li>
        {getPaginationItems()}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => onPageChange(currentPage + 1)}>
            {blogsPage.next}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
