import CompHeader from '../../components/CompHeader';
import { useLanguage } from '../../store/languageContext';

export default function about() {
  const { translations } = useLanguage();
  const about = translations?.aboutUsPage || {};
  return (
    <>
      <CompHeader title={about.aboutUs} />
      <section className="section about-2 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="about-item pr-3 mb-5 mb-lg-0">
                <span className="h6 text-color">{about.subHeading}</span>
                <h2 className="mt-3 mb-4 position-relative content-title">{about.heading}</h2>
                <p className="mb-5">{about.desc}</p>

                <a href="#" className="btn btn-main btn-round-full">
                  {about.getStartedBtn}
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-item-img">
                <img src="images/about/home-7.jpg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
