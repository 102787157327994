import { useLanguage } from '../../store/languageContext';

export default function ContactUs() {
  const { translations } = useLanguage();
  const contact = translations?.contactPage || {};
  return (
    <section className="contact-form-wrap section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <form id="contact-form" className="contact__form" method="post" action="mail.php">
              <div className="row">
                <div className="col-12">
                  <div
                    className="alert alert-success contact__msg"
                    style={{ display: 'none' }}
                    role="alert">
                    {contact.successMsg}
                  </div>
                </div>
              </div>
              <span className="text-color">{contact.subHeading}</span>
              <h3 className="text-md mb-4">{contact.heading}</h3>
              <div className="form-group">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder={contact.namePlaceholder}
                />
              </div>
              <div className="form-group">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder={contact.emailPlaceholder}
                />
              </div>
              <div className="form-group-2 mb-4">
                <textarea
                  name="message"
                  className="form-control"
                  rows={4}
                  placeholder={contact.messagePlaceholder}></textarea>
              </div>
              <button className="btn btn-main" name="submit" type="submit">
                {contact.sendBtn}
              </button>
            </form>
          </div>

          <div className="col-lg-5 col-sm-12">
            <div className="contact-content pl-lg-5 mt-5 mt-lg-0">
              <span className="text-muted">{contact.subHeading1}</span>
              <h2 className="mb-5 mt-2">{contact.heading1}</h2>

              <ul className="address-block list-unstyled">
                <li>
                  <i className="ti-direction mr-3"></i>North Main Street,Brooklyn Australia
                </li>
                <li>
                  <i className="ti-email mr-3"></i>
                  {contact.email}: contact@mail.com
                </li>
                <li>
                  <i className="ti-mobile mr-3"></i>
                  {contact.phone}:+88 01672 506 744
                </li>
              </ul>

              <ul className="social-icons list-inline mt-5">
                <li className="list-inline-item">
                  <a href="http://www.facebook.com" target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="http://www.twitter.com" target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="http://www.linkedin.com" target="_blank" rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
