import { languages } from '../utils/constants';
import axios from './axiosInstance';

export const getAllPosts = (
  pageNumber: string,
  pageSize: string,
  order: string,
  orderBy: string,
  language: string,
  subFilters?: {
    tag?: string;
    category?: string;
    startDate?: any;
    endDate?: any;
    state?: string;
  }
) => {
  const lang = languages.find((l) => l.code === language)?.name || '';
  const params: {
    pageNumber: string;
    pageSize: string;
    order: string;
    orderBy: string;
    language: string;
    tag?: string;
    category?: string;
    startDate?: any;
    endDate?: any;
    state?: string;
  } = {
    pageNumber: pageNumber || '',
    pageSize: pageSize || '',
    order: order || '',
    orderBy: orderBy || '',
    tag: subFilters?.tag || '',
    category: subFilters?.category || '',
    language: lang,
    startDate: subFilters?.startDate || '',
    endDate: subFilters?.endDate || '',
    state: subFilters?.state || ''
  };
  return axios.get(`/post?${new URLSearchParams(params)}`).then((res) => res.data);
};

export const getPostById = (id: string) => {
  return axios.get(`/post/${id}`).then((res) => res.data);
};

export const postComment = (data: any) => {
  return axios.post('/comment', data).then((res) => res.data);
};

export const postLikeDislike = (data: any) => {
  return axios.post('/like', data).then((res) => res.data);
};

export const getAllCategories = () => {
  return axios.get('/category').then((res) => res.data);
};
