import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';
import About from '../pages/about';
import Blog from '../pages/blog';
import Blogs from '../pages/blogs';
import ContactUs from '../pages/contactUs';

const PageLayout = (props: { children: React.ReactNode }) => {
  return (
    <div style={{ width: '100%' }} className="main-wrapper">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export default function Routes() {
  return (
    <RouterRoutes>
      <Route index element={<Navigate to="/blogs" />} />
      <Route
        path="blogs/:tag?"
        element={
          <PageLayout>
            <Blogs />
          </PageLayout>
        }
      />
      <Route
        path="blogs/tag/:tag"
        element={
          <PageLayout>
            <Blogs />
          </PageLayout>
        }
      />
      <Route
        path="blogs/category/:category"
        element={
          <PageLayout>
            <Blogs />
          </PageLayout>
        }
      />
      <Route
        path="about"
        element={
          <PageLayout>
            <About />
          </PageLayout>
        }
      />
      <Route
        path="contact-us"
        element={
          <PageLayout>
            <ContactUs />
          </PageLayout>
        }
      />
      <Route
        path="/blog/:blogId"
        element={
          <PageLayout>
            <Blog />
          </PageLayout>
        }
      />
      <Route
        path="*"
        element={
          <PageLayout>
            <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
              <h3>404</h3>
              <h4>Page Not Found</h4>
            </div>
          </PageLayout>
        }
      />
    </RouterRoutes>
  );
}
