import './App.css';

import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import { LanguageProvider } from './store/languageContext';

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: '#2e7d32',
              color: '#fff'
            },
            duration: 5000
          },
          error: {
            style: {
              background: '#ff4b4b',
              color: '#fff'
            },
            duration: 5000
          },
          loading: {
            style: {
              background: '#007FFF',
              color: '#fff'
            },
            duration: 10000
          }
        }}
      />
    </LanguageProvider>
  );
}

export default App;
