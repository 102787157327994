import React from 'react';

export default function CompHeader({ title }: { title: string }) {
  return (
    <section className="page-title bg-1">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block text-center">
              {/* <span className="text-white">About Us</span> */}
              <h1 className="text-capitalize mb-4 text-lg">{title}</h1>
              {/* <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href="index.html" className="text-white">
                      Home
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <span className="text-white">/</span>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="text-white-50">
                      About Us
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
