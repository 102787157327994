import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { getAllPosts } from '../../api/posts';
import CompHeader from '../../components/CompHeader';
import Pagination from '../../components/Pagination';
import { useLanguage } from '../../store/languageContext';
import { debounce, formatDate } from '../../utils';
import BlogCardSkeleton from './BlogCardSkeleton';

export default function Index() {
  const { tag, category } = useParams();
  const [posts, setPosts] = useState([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { language, translations } = useLanguage();
  const [filterCategory, setFilterCategory] = useState<string>('');
  const [dates, setDates] = useState<{ startDate: any; endDate: any }>({
    startDate: '',
    endDate: ''
  });
  const [state, setState] = useState<string>('');
  const [calledGetPosts, setCallGetPosts] = useState<boolean>(false);

  const blogsPage = translations?.blogsPage || {};

  const getPosts = useCallback(() => {
    if (language) {
      setLoading(true);
      if (calledGetPosts) {
        getAllPosts((currentPage - 1).toString(), '10', 'DESC', 'createdAt', language, {
          tag,
          category: category || filterCategory || '',
          startDate: dates.startDate,
          endDate: dates.endDate,
          state
        })
          .then((res) => {
            if (res.success) {
              setPosts(res.body.rows);
              setCount(res.body.count);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    }
  }, [currentPage, dates, filterCategory, state, language, category, tag, calledGetPosts]);

  const debouncedGetPosts = useCallback(
    debounce(() => getPosts(), 1000),
    [getPosts]
  );

  // Only trigger the debounced fetch whenever the relevant dependencies change
  useEffect(() => {
    debouncedGetPosts(); // This will be the only place triggering the data fetch
  }, [debouncedGetPosts, currentPage, dates, filterCategory, state, language, category, tag]);

  // Geolocation effect
  useEffect(() => {
    const fetchAddress = async () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
          );
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
            const stateComponent = addressComponents.find((component: any) =>
              component.types.includes('administrative_area_level_1')
            );
            setState(stateComponent.long_name);
            setTimeout(() => {
              setCallGetPosts(true);
            }, 300);
          }
        },
        (error) => {
          setCallGetPosts(true);
          console.error('Error fetching geolocation:', error);
        }
      );
    };
    fetchAddress();
  }, []);

  function getFirst150Chars(htmlContent: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const firstParagraph = doc.querySelector('p');
    if (firstParagraph) {
      return `${firstParagraph?.textContent?.slice(0, 150)}...` || '';
    } else {
      return '';
    }
  }

  return (
    <>
      <CompHeader title={blogsPage.heading} />

      <section className="section blog-wrap bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card border-0 mb-2 p-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{blogsPage.startDate}</label>
                      <input
                        className="form-control"
                        type="date"
                        name="startDate"
                        id="startDate"
                        placeholder={blogsPage.startDate}
                        onChange={(e) => setDates({ ...dates, startDate: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{blogsPage.endDate}</label>
                      <input
                        className="form-control"
                        type="date"
                        name="endDate"
                        id="endDate"
                        placeholder={blogsPage.endDate}
                        onChange={(e) => setDates({ ...dates, endDate: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {(tag || category) && (
              <div className="col-12">
                <div className="card border-0 mb-2 p-3">
                  {tag
                    ? `${blogsPage.tagsDesc} ${tag}`
                    : blogsPage.categoryDesc?.replace('{category}', category)}
                </div>
              </div>
            )}

            {loading &&
              Array.from({ length: 10 }).map((_, index) => <BlogCardSkeleton key={index} />)}

            {posts.map((post: any) => (
              <div className="col-lg-6 col-md-6 mb-5" key={post.id}>
                <div className="blog-item">
                  <div className="blog-item-content-listing bg-white p-5">
                    <div className="blog-item-meta bg-gray py-1 px-2">
                      {post.categories?.length > 0 && (
                        <span className="text-muted text-capitalize mr-3">
                          <i className="ti-pencil-alt mr-2"></i>
                          {post.categories[0].name}
                        </span>
                      )}

                      <span className="text-muted text-capitalize mr-3">
                        <i className="ti-comment mr-2"></i>
                        {post.comments?.length || 0} {blogsPage.comments}
                      </span>
                      <span className="text-black text-capitalize mr-3">
                        <i className="ti-time mr-1"></i> {formatDate(post.createdAt)}
                      </span>
                    </div>

                    <h3 className="mt-3 mb-3">
                      <Link to={`/blog/${post.id}`}>{post.title?.replaceAll('"', '')}</Link>
                    </h3>
                    <p className="mb-4">{getFirst150Chars(post.content)}</p>

                    <Link
                      to={`/blog/${post.id}`}
                      className="btn btn-small btn-main btn-round-full learn-more-btn">
                      {blogsPage.learnMoreBtn}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(count / 10)}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </section>
    </>
  );
}
